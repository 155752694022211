import React                  from 'react';
import Constants              from '../flux/Constants';
import {VXPay, VXPayAction}   from './VXPay';
import Flux                   from '../flux/Flux';
import {getCookie, setCookie} from './CommonUtils';
import {openModalBox}         from '../components/ModalBox/ModalBoxFactory';
import withSuspense           from '../components/HigherOrderComponents/Utility/withSuspense';

const openVideoUrlInPlayer = React.lazy(() => import('../components/Gallery/VideoPlayer').then(module => ({default: module.openVideoUrlInPlayer})));

let teaserContextState = null;

function initTeaserContextState() {
	if (teaserContextState === null) {
		const teaserStateCookie = getCookie(Flux.Constants.CookieNames.TEASER_SHOW);

		// no cookie set?
		if (!teaserStateCookie) {
			teaserContextState = {};
		}
		// old cookie format?
		else if (teaserStateCookie === "0") {
			teaserContextState = {
				'default': false,
			};
		}
		// try to parse the state
		else {
			try {
				teaserContextState = JSON.parse(teaserStateCookie);
			} catch {
				teaserContextState = {};
			}
		}
	}
}

function storeTeaserContextState() {
	setCookie(Flux.Constants.CookieNames.TEASER_SHOW, JSON.stringify(teaserContextState), 1, '/', '', true, Flux.Constants.CookieSameSiteAttributes.LAX);
}

export default {
	callAction(action) {
		switch (action.type) {
			case Constants.ActionTypes.Teaser.OPEN_TRIAL_ABO:
				VXPay.openTrialAboPaytour(action.options);
				break;
			case Constants.ActionTypes.Teaser.OPEN_PAYTOUR:
				if (Flux.Guest.isLoggedIn()) {
					VXPay.openPaytour(action.options);
				} else {
					const vxpayAction = new VXPayAction(Flux.Constants.ActionTypes.VXPay.SHOW_PAYTOUR, {options: action.options});
					VXPay.openSignupOrLogin(action.options, vxpayAction);
				}
				break;
			case Constants.ActionTypes.Teaser.OPEN_PROMOCODE:
				if (!Flux.Guest.isLoggedIn()) {
					VXPay.openSignupOrLogin(action.options);
				} else {
					VXPay.openPromoCode(action.options);
				}
				break;
			case Constants.ActionTypes.Teaser.OPEN_SIGNUP:
				if (!Flux.Guest.isLoggedIn()) {
					VXPay.openSignupOrLogin(action.options);
				}
				break;
			case Constants.ActionTypes.Teaser.OPEN_AVS:
				VXPay.openAVS();
				break;
			case Constants.ActionTypes.Teaser.OPEN_VOICECALL:
				VXPay.openVoicecall();
				break;
			case Constants.ActionTypes.Teaser.OPEN_VIDEO:
				const {container, src} = action.options;

				withSuspense(openVideoUrlInPlayer(
					container,
					src,
					[],
					true,
					true
				));
				break;
			case Constants.ActionTypes.Teaser.OPEN_MODALBOX:
				openModalBox(action.options.id);
				break;
			default:
		}
	},

	parseAction(actionString) {
		let teaserAction;
		try {
			teaserAction = actionString ? JSON.parse(actionString) : {};
		} catch { /* no action */
		}

		return teaserAction;
	},

	/**
	 * Check, if teaser context can be displayed
	 * @param {string} context
	 * @returns {boolean|*}
	 */
	isTeaserContextVisible(context) {
		initTeaserContextState();

		return typeof teaserContextState[context] === 'undefined' || teaserContextState[context];
	},

	/**
	 * Hide teasers from this teaser context
	 * @param {string} context
	 */
	hideTeaserContext(context) {
		initTeaserContextState();

		teaserContextState[context] = false;
		storeTeaserContextState();
	},
};
